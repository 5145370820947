<template>
  <div class="first-coupon_container">
    <FirstCouponBg
      ref="FirstCouponBgRef"
      :class="firstCouponClass"
      :fillColor="couponFillColor.fillColor"
      :borderColor="couponFillColor.borderColor"
      :lineColor="couponFillColor.lineColor"
    />
    <div
      class="first-coupon_box"
      :style="{height: `${heightValue}px`}"
    >
      <!-- 右边 -->
      <div class="first-coupon_right">
        <template v-if="configData?.couponData?.type != 0">
          <div class="coupon-right_extra">
            <div class="coupon-extra_top">
              {{ langText?.SHEIN_KEY_PWA_29165 }}
            </div>
          </div> 
          <div
            v-if="configData?.couponData?.type == 2"
            class="coupon-right_off"
          >
            <!-- 折扣 -->
            <div class="coupon-off_num">
              {{ configData?.couponData?.value }}
            </div>
            <div>
              <div class="coupon-off_top">
                {{ '%' }}
              </div>
              <div class="coupon-off_bottom">
                {{ langText?.SHEIN_KEY_PWA_29164 }}
              </div>
            </div>
          </div>
          <!-- 金额 -->
          <template v-if="configData?.couponData?.currency == 'USD' && configData?.couponData?.type == 1">
            <div
              class="coupon-price_box"
            >
              <div class="coupon-price_currency">
                $
              </div>
              <div class="coupon-price_num">
                {{ configData?.couponData?.value }}
              </div>
            </div>
          </template>
          <template v-if="configData?.couponData?.currency != 'USD' && configData?.couponData?.type == 1">
            <div
              class="coupon-price_compatible"
            >
              <p>
                {{ configData?.couponData?.amountWithSymbol }}
              </p>
            </div>
          </template>
        </template>
        <template v-if="configData?.couponData?.type == 0">
          <div class="coupon-freeShipping">
            <p>{{ langText?.SHEIN_KEY_PWA_29304 }}</p>
          </div>
        </template>
      </div>
      <!-- 左边 -->
      <div class="first-coupon_left">
        <div class="coupon-left_content">
          <div class="coupon-left_title">
            <p>{{ langText?.SHEIN_KEY_PWA_29158 }}</p>
          </div>
          <div class="coupon-left_desc">
            <p>{{ configData?.couponData?.desc }}</p>
          </div>
        </div>
        <div
          class="coupon-left_btn"
          @click="handleUseCopon"
        >
          <p>{{ langText?.SHEIN_KEY_PWA_29181 }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, nextTick, computed } from 'vue'
import FirstCouponBg from './Icon/FirstCouponBg'
import { clickNewUserCoupon } from '@login/utils/loginAnalysis'
import { useStore } from '@login/hooks/utils.js'

const { SiteUID } = gbCommonInfo

const store = useStore()

const props = defineProps({
  langText: {
    type: Object,
    default: () => {}
  },
  configData: {
    type: Object,
    default: () => {}
  }
})

const FirstCouponBgRef = ref(null)
const heightValue = ref('')

const couponFillColor = computed(() =>{
  const couponType = store.state.login.registerSuccessPopConfig.couponData.type
  const fillColor = couponType == 0 ? '#EEFFF2' : '#FFF6F3'
  const borderColor = couponType == 0 ? '#8BE07A' : '#FFE2CF'
  const lineColor = couponType == 0 ? '#C6F3C2' : '#FF3F27'
  return { fillColor, borderColor, lineColor }
})

const firstCouponClass = computed(()=> {
  if(SiteUID == 'pwar'){
    return 'flip-horizontal'
  }
  return ''
})

const handleUseCopon = async () => {
  const AddOnItem =  (await import('public/src/pages/common/addOnItem/index')).default
  AddOnItem.open({
    props: {
      type: 'coupon',
      coupon: props.configData?.couponData?.couponCode,
      queryInfo: {
        addOnType: 2, // 问产品拿
        sceneId: 152, // 推荐场景id
        goodsIds: [],
        cateIds: [],
        direct_tag: props.configData?.couponData?.direct_tag,
        return_tag: props.configData?.couponData?.return_tag,
        adp: [], 
      },
      saInfo: {
        // 主要用于埋点
        activity_from: 'registration_success_add',
        state: 'registration_success_add'
      }
    },
    on: {
      close () {
      }
    }
  })
  clickNewUserCoupon()
}

const updateViewportHeight = () => {
  nextTick(() => {
    const height = FirstCouponBgRef.value?.$el?.clientHeight
    heightValue.value = height
  })
}

onMounted(() => {
  window.addEventListener('resize', updateViewportHeight)
  updateViewportHeight()
})
onUnmounted(() => {
  window.removeEventListener('resize', updateViewportHeight)
})
// eslint-disable-next-line semi
;
</script>

<script>
export default {
  name: 'FirstOrderCoupon',
}
</script>

<style lang="less" scoped>
@vw: 375/100vw;
.first-coupon_container{
  margin-top: 20px;
  position: relative;
  width: 100%;
  height: auto;
}
.flip-horizontal{
    transform: scaleX(-1);
  }
 .first-coupon_box{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    overflow: hidden;
    .first-coupon_right{
      width: 30%;
      height: 100%;
      padding: 12 / @vw 16 / @vw;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: -2 / @vw;
      color: #F2363D;
      font-family: 'SF-Pro';
    }
    .first-coupon_left{
      padding: 10 / @vw 16 / @vw 10 / @vw 12 / @vw;
      display: flex;
      justify-content: space-between;
      flex: 1;
      height: 100%;
      align-items: center;
      // font-family: 'SF-Pro';
    }
  }
  .coupon-right_extra{
    width: 74 / @vw;
    height: 19 / @vw;
    font-weight: 860;
    text-transform: capitalize;
     .coupon-extra_top{
        font-size: 16 / @vw;
      }
  }
  .coupon-off_num{
    font-weight: 860;
    font-size: 36 / @vw;
  }
  .coupon-right_off{
    width: 74 / @vw;
    height: 43 / @vw;
    display: flex;
    align-items: center;
    gap: 2 / @vw;
    font-weight: 1000;
    font-size: 14 / @vw;
    .coupon-off_top{
      height: 17 / @vw;
    }
    .coupon-off_bottom{
      height: 17 / @vw;
    }
  }
  .coupon-left_content{
    flex: 1;
    height: 51 / @vw;
    margin-right: 12 / @vw;
    transform: translateY(-3px);
    .coupon-left_title{
      width: 149 / @vw;
      height: 19 / @vw;
      font-size: 16 / @vw;
      line-height: normal;
      font-weight: 700;
      text-transform: capitalize;
      color: @sui_color_gray_dark1;
      p{
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis; 
      }
    }
    .coupon-left_desc{
      display: flex;
      align-items: center;
      width: 149 / @vw;
      height: 42 / @vw;
      // margin-top: 4 / @vw;
      font-size: 12 / @vw;
      font-weight: 400;
      color: @sui_color_gray_dark2;
      opacity: 0.8;
      font-style: normal;
      line-height: normal;
      p{
        word-wrap: break-word;
        overflow: hidden; 
        display: -webkit-box; 
        -webkit-line-clamp: 3; 
        -webkit-box-orient: vertical;
        text-overflow: ellipsis; 
      }
    }
  }
  .coupon-price_box{
    width: 54 / @vw;
    height: 43 / @vw;
    display: flex;
    align-items: center;
    font-style: normal;
    line-height: normal; 
    .coupon-price_currency{
      font-size: 16 / @vw;
      font-weight: 860;
      transform: translateY(6/@vw);
    }
    .coupon-price_num{
      font-size: 36 / @vw;
      font-weight: 860;
    }
  }
  .coupon-price_compatible{
    width: 83 / @vw;
    height: 29 / @vw;
    font-weight: 860;
    display: flex;
    font-size: 18 / @vw;
    align-items: center;
    font-style: normal;
    line-height: normal; 
    p{
      white-space: nowrap; 
      overflow: hidden;
      text-overflow: ellipsis; 
    }
  }
  .coupon-freeShipping{
    width: 81 / @vw;
    height: 51 / @vw;
    display: flex;
    align-items: center;
    color: #22A900;
    font-size: 14 / @vw;
    font-style: normal;
    font-weight: 1000;
    line-height: normal;
    text-transform: capitalize;
    p{
      word-wrap: break-word;
      overflow: hidden; 
      display: -webkit-box; 
      -webkit-line-clamp: 3; 
      -webkit-box-orient: vertical;
      text-overflow: ellipsis; 
    }
  }
  .coupon-left_btn{
    display: flex;
    padding: 6 / @vw;
    justify-content: center;
    align-items: center;
    width: 60 / @vw;
    height: 28 / @vw;
    flex-shrink: 0;
    border-radius: 10000 / @vw;
    color: #fff;
    font-size: 12 / @vw;
    font-weight: 860;
    font-family: 'SF-Pro';
    text-transform: uppercase;
    background: #F2363D;
   p{
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
   }
  }
</style>
