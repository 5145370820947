import Vue from 'vue'
import { Toast, Drawer, Button, Field, Loading, Dialog, ButtonGroup, ButtonGroupItem, Checkbox, Popover, Alert, NetworkErrorAlert } from '@shein/sui-mobile'
import LoginInput from  '../libs/LoginInput/index.vue'
// eslint-disable-next-line import/no-unresolved
import LazyMountModal from '@lib/LazyMountModal/index.vue'
import { LazyMount } from '@shein/sui-mobile'

;[
  Field,
  Button,
  Checkbox,
  Dialog,
  ButtonGroup,
  ButtonGroupItem,
  Loading,
  Drawer,
  Checkbox,
  Popover,
  Alert,
  LazyMount,
  NetworkErrorAlert
].forEach((item) => {
  Vue.use(item)
})
Vue.component('LazyMount', LazyMount)
Vue.component('LoginInput', LoginInput)
Vue.component('LazyMountModal', LazyMountModal)

if (typeof window !== 'undefined') {
  // 对外提供的调用方法
  Vue.prototype.$evt = window._GB_LoginEventCenter_
  window.signupTime = 0 // 注册时长
  window.signupTimeTag = null
  
  const isAsComponent = !/user(\/auth)?\/login(\/)?$/.test(location.pathname) // 是否在登录页面,否则认为作为组件展示
  window.isLoginPageAsCompnent = isAsComponent // 全局变量，用来在登录成功后判断跳转方式
}

Vue.prototype.$toast = Toast
