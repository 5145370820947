<template>
  <div class="page__login_emailLoginPage">
    <div class="main-content-body">
      <LoginHead
        :showback="true"
        :showLogo="false"
        @back="handleBack"
      />
      <h2>
        <p>{{ langText.SHEIN_KEY_PWA_23928 }}</p>
      </h2>
      <div class="main-content">
        <!-- 邮箱 -->
        <div class="input-filed">
          <LoginInput
            :value="!switchAccount?.show ? newUIStates?.desensitize_alias : switchAccount?.accountAlias"
            :label="langText.SHEIN_KEY_PWA_15325"
            ui-type="new"
            :disabled="true"
          />
        </div>
        <!-- 密码 -->
        <div class="input-filed">
          <LoginInput
            v-model="password"
            type="password"
            :tips="passwordTip.txt || aliasInputTip.txt"
            :label="langText.SHEIN_KEY_PWA_15072"
            :mode="'email'"
            ui-type="new"
            @input="handlePasswordAliasChange"
          />
        </div>
        <div
          class="mshe-text-right login__forgotPass"
        >
          <a
            href="javascript:;"
            da-event-click="2-8-55"
            data-type="email" 
            @click="handleOpenForgetPsd"
          >{{ langText.SHEIN_KEY_PWA_15582 }}</a>
        </div> 
        <div class="c-form-group">
          <div
            v-show="isShowGoogleRecaptcha"
            ref="loginRecaptchaVnode"
          ></div>
          <p
            v-show="isShowGoogleRecaptchaErr"
            class="sign-in-verification-error text-error"
          >
            Verification has failed. Please try again.
          </p>
        </div>
        <div class="actions">
          <SButton
            class="button"
            :type="['primary', 'H88PX']"
            @click="handleEmailLogin"
          >
            {{ langText?.SHEIN_KEY_PWA_15578 }}
          </SButton>
        </div>
      </div>
    </div>
    <div class="main-content-footer">
      <WeekPrivacy
        v-if="newPrivacyAbt?.type == 'no'"
        :type="'login'"
        location="email_login"
      />
    </div>
    <TemporaryVue
      ref="temporaryRef"
      :langText="langText"
    />
  </div>
</template>

<script setup>
/* global __GB_LoginModalInfo__ */
import { useStore, useEventBus } from '@login/hooks/utils'
import { checkPw, formatErrorLoginInput, newPrivacyCache, formatNormalErrorMessage, urlRedirection, isFromShopping } from '@login/util'
import { showInputTips } from '@login/utils/index.js'
import { windowCacheDeleteCb } from '@shein/common-function'
import { ref, computed, watch, onMounted } from 'vue'
import LoginHead from '@login/views/NewMainUI/components/head.vue'
import LoginInput from  '@login/libs/LoginInput/index.vue'
import WeekPrivacy from '@login/views/NewMainUI/components/week_privacy.vue'
import TemporaryVue from './Temporary'
import { loginSer as emialLoginSer, getMemeberList } from '@login/service'
import { geetestChallenge } from 'public/src/pages/common/RiskCommon/common/geetestChallenge.js'
import { saSend } from '@login/analysis/util'
import analysisIndex from '@login/analysis/index'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import useCommonHandle from '@login/hooks/useCommonHandle.js'
const { sensorsSend, loginPageFrom } = analysisIndex
const { langPath, host, lang } = gbCommonInfo
const { GOOGLE_VERIFY_SITEKEY } =
  typeof __GB_LoginModalInfo__ === 'object' ? __GB_LoginModalInfo__ : {}

const store = useStore()
const evt = useEventBus()

const temporaryRef = ref()
const password = ref('')
const passwordTip = ref({ show: false, txt: '' })
const aliasInputTip = ref({ show: false, txt: '' })
const isShowGoogleRecaptcha = ref(false)
const isShowGoogleRecaptchaErr = ref(false)
const loginRecaptchaVnode = ref()
const loginGoogleRecaptcha = ref({ id: '', token: '' })
const riskInfo = ref({})
const revealRes = ref({})

const { setCommonShowPop } = useCommonHandle()

const switchAccount = computed(() => store.state.login.switchAccount)
const langText = computed(() => store.state.login.langText)
const relatedAcc = computed(() => store.state.login.relatedAcc)
const phoneAbt = computed(() => store.state.login.phoneAbt)
const newPrivacyAbt = computed(() => store.state.login.newPrivacyAbt)
const defaultLocation = computed(() => store.state.login.defaultLocation)
const newUIStates = computed(() => store.state.login.newUIStates)
const isLoginAccount = computed(() => store.state.login?.preloadData?.uid)

watch(() => newUIStates.value?.showType, async (val) => {
  if(val && newUIStates.value?.showType == 'encryptionEmail'){
    saSend('2-8-105', { scene: 'login', type: 'email', acc: relatedAcc.value })
  }
}, { immediate: true })

const handleBack = () => {
  if(switchAccount.value?.show){
    store.commit('login/setSwitchAccount', { show: false })
    return
  }
  if(newUIStates.value.showType == 'encryptionEmail'){
    store.commit('login/setnewUIStates', { showType: 'continue', isToLogin: false, isSwitch: false })
  }
  password.value = ''
  showInputTips(passwordTip.value, '')
  showInputTips(aliasInputTip.value, '')
}

const handleOpenForgetPsd = () => {
  store.commit('login/setForgetPassword', { show: true, email: '', type: 'email' })
}
const handlePasswordAliasChange = () => {
  showInputTips(passwordTip.value, '')
}
const handleEmailLogin = async () => {
  const { accountAlias, accountType, accountSecurity, locationRiskId } = switchAccount.value
  const { desensitize_alias,  encryption_alias, locationRiskId: loginLocationRiskId } = newUIStates.value
  const isSwitchAccount = switchAccount.value?.show
  if(!checkPw(password.value, passwordTip.value)){
    const alias = isSwitchAccount ? accountAlias : desensitize_alias
    const samsg = formatErrorLoginInput(alias, password.value)
    saSend('2-8-108', { mode: 'email', result_reason: samsg, acc: relatedAcc.value })
    return
  }
  const params = { 
    email: accountAlias,
    accountAlias: isSwitchAccount ? accountAlias : desensitize_alias, 
    accountType: isSwitchAccount ? accountType : 7, 
    encryption_alias: isSwitchAccount ? accountSecurity : encryption_alias, 
    password: password.value, 
    daId: '2-8-108', 
    locationRiskId: isSwitchAccount ? locationRiskId : loginLocationRiskId
  }
  const $googleRecaptcha = document.getElementById('g-recaptcha-response')
  if ($googleRecaptcha && $googleRecaptcha.value){
    params.g_recaptcha_response = $googleRecaptcha.value
  }
  params.clause_flag = 0
  if(newPrivacyAbt.value?.type == 'yes'){
    params.clause_flag = 1
    if (newPrivacyCache.get(defaultLocation.value?.forceId || defaultLocation.value?.id || '')) {
      params.clause_agree = 1
    }
  }
  params.locationId = newPrivacyAbt.value?.origin == 'mix' ? (defaultLocation.value?.forceId || defaultLocation.value?.id || '') : ''
  requestEmailLogin(params)
}

const requestEmailLogin = async (params) => {
  evt.$emit('event-loading', true)
  const { isSwitch } = newUIStates.value
  isSwitch ? params.isSwitchUid = isLoginAccount.value : ''
  const res = await geetestChallenge(emialLoginSer, params, 'login')
  if (!res) {
    evt.$emit('event-loading', false)
    return
  }
  const { apiResData = {}, status } = res
  if (status == 'error') {
    showInputTips(aliasInputTip.value, langText.value.SHEIN_KEY_PWA_16274)
    evt.$emit('event-loading', false)
    return
  }
  if (status == 'close') {
    evt.$emit('event-loading', false)
    return
  }
  const response = apiResData || {}
  const { code, info, needVerify, pass_err } = response
  if (params.daId) {
    saSend(params.daId, { mode: 'email', code, acc: relatedAcc.value })
  } else {
    sensorsSend('2-8-77', { code, type: 'email' })
  }
  if (code == 0) {
    evt.$emit('event-loading', false)
    store.commit('login/setSwitchAccount', { show: false })
    windowCacheDeleteCb({ keys: ['SHELL_HEAD', 'SHELL_BODY', 'dynamicData', 'homeDynamicData', 'campaignsDynamicData'] })
    window.appEventCenter?.$emit('refreshCategoryCrowdInfo')
    try {
      localStorage.removeItem('user_need_edit_pwd')
    } catch (e) {
      // empty
    }
    const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'requestEmailLogin' })
    if (memberId) window?.emarsys?.pushCommend('setCustomerId', memberId)
    if (phoneAbt.value?.emailloginBindPhone == 'on' && !setCommonShowPop() && !isFromShopping() && relatedAcc.value?.from != 'order_list') {
      checkAccountList(response, params.accountAlias || '')
      return
    }
    handleBack()
    setTimeout(() => {
      urlRedirection(false, response, false, 'email')
    }, 300)
    return
  }
  if (code == 300206) {
    window.location.href = `${host}${langPath}user/logout` 
    return
  }
  if (code == 400504 && switchAccount.value?.showType == 'email') {
    evt.$emit('event-loading', false)
    showInputTips(aliasInputTip.value, langText.value?.SHEIN_KEY_PWA_15588)
    if (pass_err > 3 && lang !== 'es' && !needVerify) {
      store.commit('login/changeDialogs', { key: 'isShowPasswordErrorModal', val: { show: true } })
    }
    return
  }
  if (code == 400593) {
    evt.$emit('event-loading', false)
    store.commit('login/setNewPrivacyModal', {
      from: 'email',
      cb: () => {
        params.clause_agree = 1
        requestEmailLogin(params)
      }
    })
    return
  }
  if (needVerify) {
    isShowGoogleRecaptcha.value = true
    resetGoogleRecaptcha()
  } else {
    isShowGoogleRecaptcha.value = false
  }
  if (code == -404 || code == -405) {
    showInputTips(aliasInputTip.value, langText.value.SHEIN_KEY_PWA_14899)
    evt.$emit('event-loading', false)
    window?.exceptionReport.geetest()
    return
  }
  if (code == 402906) {
    const { extend_info = {} } = info || {}
    const { actions = [] } = extend_info
    const actionName = actions[0]?.name || ''

    if (actionName == 'email_captcha' && actions.length == 1) {
      const aliasMark = actions[0]?.param?.email || params.email
      const alias = params.email || actions[0]?.param?.email
      const encrypt_email = actions[0]?.param?.encrypt_email || ''

      riskInfo.value = {
        aliasMark,
        alias,
        encrypt_email,
        risk_id: info?.risk_id,
        login_from: loginPageFrom(),
        cb: () => {
          Object.assign(params, { uberctx_risk_uuid: info?.risk_id })
          requestEmailLogin(params)
        }
      }
      setTimeout(() => {
        store.commit('login/changeDialogs', {
          key: 'showEmailValidate',
          val: { show: true, riskInfo: riskInfo.value }
        })
      }, 0)
      evt.$emit('event-loading', false)
      return
    }
    evt.$emit('event-loading', false)
    const { accountAlias, accountSecurity } = params
    Object.assign(params, { email: accountAlias, encrypt_email: accountSecurity })
    if (
      temporaryRef.value?.runRiskChalleage(
        { params, message_type: 'login_confirm', scene: 'login' },
        response,
        ({ type }) => {
          if (type == 'close') return
          password.value = ''
        }
      )
    )
      return
  }
  const errTip = formatNormalErrorMessage(response)
  showInputTips(aliasInputTip.value, errTip)
  isShowGoogleRecaptchaErr.value = false
  evt.$emit('event-loading', false)
}

const checkAccountList = async (res, email) => {
  const { account_list, subscribe_status } = await getMemeberList() || {}
  if (account_list && account_list.some(v => v.alias_type == 2)) {
    handleBack()
    return urlRedirection(false, res)
  }
  evt.$emit('event-loading', false)
  if(subscribe_status == 1){
    store.commit('login/assignState', { smsSubScribeCheckBox: true })
  }
  handleBack()
  store.commit('login/setPhoneBind', { show: true, response: res, email })
}

const resetGoogleRecaptcha = () => {
  const { id } = loginGoogleRecaptcha.value
  if (id === '') {
    const id = window?.grecaptcha.render(loginRecaptchaVnode.value, {
      sitekey: GOOGLE_VERIFY_SITEKEY,
      size: 'normal',
      callback: (res) => {
        Object.assign(loginGoogleRecaptcha.value, { token: res } )
      },
      'expired-callback': () => {
        
      },
      'error-callback': () => {
        
      }
    })
    Object.assign(loginGoogleRecaptcha.value, id)
    fixIosSafariRecaptcha()
    return
  }
  window?.grecaptcha.reset(id)
  fixIosSafariRecaptcha()
}

const fixIosSafariRecaptcha = (times = 0) => {
  window.__fixIosSafariRecaptchaTimer = setTimeout(() => {
    if (window.__fixIosSafariRecaptchaTimer) clearTimeout(window.__fixIosSafariRecaptchaTimer)
    if (times > 15) return
    const $iframe = $('iframe[src*="recaptcha/api2/bframe"]')
    if ($iframe.length > 0) {
      $iframe.parent().parent().css('transform', 'translateZ(200000px)').css('z-index', 200000)
    } else {
      fixIosSafariRecaptcha(++times)
    }
  }, 500)
}

onMounted(() => {
  
})

// eslint-disable-next-line semi
;
</script>

<script>
export default {
  name: 'EmailLoginPage'
}
</script>

<style lang="less" scoped>
.page__login_emailLoginPage{
    padding: 0 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .main-content-body {
      flex: 1 1 100%;
    }
    .main-content-fotoer {
      flex: 0 0 auto;
    }
  h2 {
  padding: 32px 0 0 0;
  .font-dpr(36px);
  line-height: 1;
  }
  .login__forgotPass {
    padding: 12px 0;
    a {
      text-decoration: none;
    }
  }
  .actions {
    padding-top: 16px;
    button {
      width: 100%;
    }
  }
  /deep/.page__login-inputComp_disabled {
    .page__login-inputComp_con {
      background: @sui_color_gray_weak2;
      border-color: transparent;
    }
  }
  /deep/.page__login-weekPrivacy {
    position: relative;
    bottom: 0;
    padding: 0;
    padding-bottom: 10px;
  }
}
</style>
