export default function createState () {
  return {
    WEB_CLIENT: '',
    isGoogleSupport: false,
    showIndex: false,
    isAsComponent: false,
    instancename: '',
    langText: {},
    preloadData: {},
    checkboxPrivacyValue: false,
    isShowFilterAreaCodeModal: false,
    filterAreaCodeTip: {
      show: false,
      area_code: {},
      aliasRes: ''
    },
    newPrivacyAbt: {
      type: '',
      origin: '', // 原始参数，因为一站多合规，type有可能会转换为yes/no/off
      loading: true,
    },
    newPrivacyModal: {
      show: false,
      from: '',
      account: '',
      cb: '',
    },
    registerPrivacyModal: {
      show: false,
      cb: '',
    },
    phoneLoginCodeMode: 1, // 手机号码登陆页密码和验证码模式切换
    phoneAbt: {
      phoneLoginPage: 'off', // 默认展示手机号登陆页面
      phoneRegisterPage: 'off', // 默认展示手机号注册页面
      phoneRegisterEntrance: 'off', // 邮箱注册展示手机号注册入口
      phoneLoginEntrance: 'off', // 邮箱登陆展示手机号登陆入口
      registerBindPhone: 'off', // 邮箱注册引导绑定手机
      emailloginBindPhone: 'off', // 邮箱登陆引导绑定手机
      resetPasswordViaSMS: 'off', // 忘记密码界面展示“通过短信重置密码”
      phoneNumManage: 'off', // 账户安全中心展示手机号管理入口
    },
    // 手机号注册国家区号编码
    countryCodes: [],
    showAreaPop: false,
    showAreadCode: false,
    areaCode: { code: '', abbr: '', cb: null },
    tabIndex: 0, // 0: login, 1: sign up
    dialogs: {
      showEmailValidate: { show: false, riskInfo: {} }, // 邮箱验证弹框
      signupEmailValidate: { show: false, opts: {} }, // 注册邮箱验证
      isShowPasswordErrorModal: { show: false },
      sendTypeChangePop: { show: false }, //手机号类型切换弹框
      thirdEmailLoginInfo: { show: false, type: '' },
      smsSubScribeModal: { show: false, cb: '' },
      bindModalInfo: {
        // 绑定登录方式相关信息
        show: false,
        type: '',
        email: '',
        repeatType: [],
      }
    },
    smsSubScribeCheckBox: false,
    sendTypeChangePop: false, //手机号类型切换弹框
    // 弹窗登陆配置信息
    loginPopConfig: {
      from: '',
    },
    // 手机号绑定
    phoneBind: {
      show: false,
      response: {},
    },
    forgetPassword: {
      show: false,
      from: '',
      type: 'email',
    },
    phonePasswordChange: {
      show: false,
      code: '',
      alias: '',
    },
    supportLocation: [], // 当前站点支持的区域
    defaultLocation: {},
    commonAbt: {
      switchSite: { login: '', register: '' },
      lineLogin: 'off',
      WhatsAppSetup: { default: 'sms', show: 'off' },
      SubscribeWhatsApp: { show: false },
      USRegisterSMS: { show: false },
      SignupSubscribeNote: false,
      NewUserRights: '',
      RelatedAccountRetention: 'off', //abt下线，代码保留，默认值off
      LoginRegisterRetian: 'off',
      LoginRegisterRetianPops: '',
      PolicyAuthorizePopup: '',
      PhoneZeroFiltered: '',
      PhoneAreaCodePopop: '',
      registerSuccess: '',
      forgetPwdQA: false,
      FBMessengerConnect: '',
      LoginPageIncentive: false,
      AccountManager: '',
      ProtectedTips: ''
    },
    fuseAbtResult: {
      // 熔断abt
      fb: false,
      google: false,
      vk: false,
      line: false,
    },
    relatedAcc: {
      show: false,
      list: [],
      type: '',
      from: '',
      isChecked: false, // 是否已查询过用户
      global: false, // 更改登陆注册ui展示模式
      abt: '', // related, free
      isRetain: false, // 是否挽留
      is_bind: '',
      is_relation: '',
      selected: { alias: '' }, // 选择的管理账号，只有related的时候才会有
    }, // 关联账号展示
    // 关联账号挽留弹框
    relatedRetainModal: false,
    switchAccount: {
      show: false,
      showType: '',
      accountType: '',
      accountAlias: '',
      accountSecurity: '',
      area_code: '',
      area_abbr: ''
    },
    accountList: [],
    newUIStates: {
      showType: '',
      mode: '', // 当前类型 email or phone or 空
      alias: '',
      type: '', // login or signup
      sendType: 'sms', // sms or whatsapp
      locationRiskId: '',
      subscribe_status: ''
    },
    loadComponent: false,
    showPointRetainModal: false,
    isShowRetainModal: false,
    closeRetainModal: '',
    RetainModalConfig: {
      type: '',
      promotion_price: '',
      from: '',
      couponData: {},
    },
    LoginButtonPoint: {
      type: '',
      promotion_price: '',
      couponData: {},
    },
    LoginButtonAnimation: [],
    isNewRegisterPop: false,
    registerSuccessPopConfig: {
      isShow: false,
      type: 'default',
      registerFrom: '',
      point: 0,
      account: '',
      productList: [],
      cb: '',
      couponData: {
      // type: 0, // 0 免邮， 1金额， 2折扣
      },
    },
    helpPageConfig: {
      isShow: false,
      registerType: '',
      tipsType: '',
    },
    publicLocalData: {
      lang: '',
      langPath: '',
      WEB_CLIENT: '',
      PUBLIC_CDN: '',
      SiteUID: '',
      GB_cssRight: false,
      IS_RW: false,
      host: ''
    },
    online: typeof navigator !== 'undefined' ? navigator.onLine : true
  }
}
