<template>
  <s-drawer
    :visible="dialogs?.thirdEmailLoginInfo?.show"
    class="pop-third-email-login-drawer"
    size="75rem"
    :append-to-body="true"
  >
    <div class="page__login-thirdEmailLoginPop">
      <h4>{{ langText.SHEIN_KEY_PWA_15578 }}</h4>
      <i
        class="iconfont icon-close-Popup close"
        @click="handleClose"
      ></i>
      <p class="desc">
        {{ langText.SHEIN_KEY_PWA_18353?.replace('{0}', dialogs?.thirdEmailLoginInfo?.type) }}
      </p>
      <div class="input-area">
        <s-field 
          ref="thirdLoginEmailInput"
          v-model="input.value"
          :label="langText.SHEIN_KEY_PWA_14945" 
          :clearable="true" 
          :required="true"
          :value-bolder="true"
          :disabled="stepTwo.show"
          :readonly="stepTwo.show"
          :note="input.txt"
          @blur="inputCheck"
          @clear="inputClear"
          @input="inputChange"
        />
        <i
          v-show="stepTwo.show"
          class="icon-sucess iconfont icon-yiwancheng_-"
        ></i>
      </div>
      <div
        v-show="stepTwo.show"
        class="input-area"
      >
        <s-field 
          ref="thirdLoginEmailInputCode"
          v-model="stepTwo.code"
          :label="langText.SHEIN_KEY_PWA_16186" 
          :clearable="false" 
          :required="true"
          :value-bolder="true"
          :max-length="6"
          :note="stepTwo.tips"
          @blur="inputCodeCheck"
          @input="inputCodeChange"
        />
        <s-button
          v-show="stepTwo.countdown <= 0"
          class="code-btn"
          :type="['primary', 'H48PX']"
          @click="handleSendCode"
        >
          {{ langText.SHEIN_KEY_PWA_15323 }}
        </s-button>
        <s-button
          v-show="stepTwo.countdown > 0"
          class="code-btn"
          :disabled="true"
          :type="['primary', 'H48PX']"
        >
          {{ stepTwo.countdown }}S
        </s-button>
      </div>
      <div
        v-show="stepTwo.show"
        class="code-tips"
      >
        <p>{{ langText.SHEIN_KEY_PWA_18482 }}</p>
        <p>{{ langText.SHEIN_KEY_PWA_18483 }}</p>
      </div>
      <div class="actions">
        <s-button
          v-show="!stepTwo.show" 
          width="100%"
          :disabled="nextStepButtonDisabled"
          :type="['primary']"
          @click="handleNextStep"
        >
          {{ langText.SHEIN_KEY_PWA_15884 }}
        </s-button>
        <s-button
          v-show="stepTwo.show" 
          width="100%"
          :disabled="stepTwo.buttonDisabled"
          :type="['primary']"
          @click="handleLogin"
        >
          {{ langText.SHEIN_KEY_PWA_15578 }}
        </s-button>
      </div>
    </div>
    <s-dialog
      :visible="registerDialogShow"
      :append-to-body="true"
    >
      <div class="page__login-thirdEmailLoginPop_tips">
        <p>{{ langText.SHEIN_KEY_PWA_18354 }}</p>
        <s-button-group
          hor
          class="actions"
          :margin="0"
        >
          <s-button-group-item
            :type="['H72PX']"
            @click="registerDialogShow=false"
          >
            {{ langText.SHEIN_KEY_PWA_14901 }}
          </s-button-group-item>
          <s-button-group-item
            :type="['primary', 'H72PX']"
            @click="toRegister"
          >
            {{ langText.SHEIN_KEY_PWA_15579 }}
          </s-button-group-item>
        </s-button-group>
      </div>
    </s-dialog>
  </s-drawer>
</template>

<script>
import { checkEmail, urlRedirection } from '../../util'
import { thirdLoginLocate, thirdLoginCodeSend, codeVerfiyLogin } from '../../service'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { mapMutations, mapState } from 'vuex'
import { geetestChallenge } from 'public/src/pages/common/RiskCommon/common/geetestChallenge.js'
import { formatLoginType } from '@login/utils/index.js'
import analysisIndex from '../../analysis/index'
const { loginPageFrom } = analysisIndex
daEventCenter.addSubscriber({ modulecode: '2-8' })

export default {
  name: 'ThirdEmailLogin',
  props: {
    langText: { type: Object, default: () => ({}) },
  },
  data () {
    return {
      input: {
        value: '',
        disabled: false,
        txt: '',
      },
      registerDialogShow: false,
      nextStepButtonDisabled: true,
      stepTwo: {
        show: false,
        buttonDisabled: true,
        code: '',
        countdown: 0,
        tips: '',
      },
    }
  },
  computed: {
    ...mapState('login', ['commonAbt', 'dialogs']),
    thirdType () {
      const types = {
        Facebook: '11',
        Google: '12',
        VK: '13',
        Line: '15',
        Kakao: '16',
        Naver: '17'
      }
      return types[this.dialogs?.thirdEmailLoginInfo?.type] || 0
    }
  },
  watch: {
    'dialogs.thirdEmailLoginInfo.type': {
      handler: function (n, o) {
        if (n !== o) {
          this.resetStatus()
        }
      }, immediate: true
    }
  },
  methods: {
    ...mapMutations('login', ['changeDialogs', 'assignState', 'setnewUIStates']),
    inputChange () {
      const v = checkEmail(this.input.value, {})
      if (this.input.value == '') this.input.txt = ''
      if (v) this.$refs.thirdLoginEmailInput.updateErrorView({ valid: true, validateMessage: '' })
      this.nextStepButtonDisabled = !v
    },
    inputClear () {
      this.$refs.thirdLoginEmailInput.updateErrorView({
        valid: true,
        validateMessage: ''
      })
      this.nextStepButtonDisabled = true
    },
    inputCheck () {
      if (!this.input.value) return
      const v = {}
      checkEmail(this.input.value, v)
      this.$refs.thirdLoginEmailInput.updateErrorView({
        valid: !v.show,
        validateMessage: v.txt
      })
      if (!v.show) this.nextStepButtonDisabled = false
    },
    inputCodeCheck () {

    },
    inputCodeChange () {
      if (this.stepTwo.code >= 6) {
        this.stepTwo.buttonDisabled = false
      }
    },
    toRegister () {
      this.registerDialogShow = false
      this.handleClose()
      this.setnewUIStates({ alias: this.input.value, mode: 'email' })
    },
    handleClose () {
      this.changeDialogs({
        key: 'thirdEmailLoginInfo',
        val: { show: false, type: '' },
      })
      this.setnewUIStates({ isToLogin: false, })
    },
    resetStatus () {
      this.input.value = ''
      this.input.txt = '',
      this.stepTwo.show = false
      this.stepTwo.code = ''
    },
    // 倒计时
    startCountdown () {
      this.countdownTimer = setTimeout(() => {
        if (this.stepTwo.countdown <= 0) {
          clearTimeout(this.countdownTimer)
          return
        }
        this.stepTwo.countdown = this.stepTwo.countdown - 1
        this.startCountdown() 
      }, 1000)
    },
    async handleNextStep () {
      const { code, tips } = await thirdLoginLocate({ email: this.input.value, third_party_type: this.thirdType })
      //
      if (code == 0) {
        this.stepTwo.show = true
        this.handleSendCode()
        return
      }
      if (code == '400505') {
        this.registerDialogShow = true
        return 
      }
      this.$toast(tips)
    },
    handleSendCode () {
      const params = {
        alias: this.input.value,
        alias_type: 1,
        scene: 'third_login_verify',
        third_party_type: this.thirdType
      }
      this.requestSendCode(params)
    },
    async requestSendCode (params) {
      // 极验验证
      const res = await geetestChallenge(thirdLoginCodeSend, params, 'send_message')
      if (!res) {
        return
      }
      const { apiResData = {}, status } = res
      if (status == 'error') {
        this.$toast(this.langText.SHEIN_KEY_PWA_17551)
        return
      }
      if (status == 'close') {
        return
      }
      const response = apiResData || {}
      const { code, tips, info } = response

      if (code == 0) {
        this.stepTwo.countdown = 60
        this.startCountdown()
        this.$toast(this.langText.SHEIN_KEY_PWA_18484)
        return
      }

      // 风控验证
      if (code == 402906) {
        const { extend_info = {} } = info || {}
        const { actions = [] } = extend_info
        const actionName = actions[0]?.name || ''
                
        if (actionName == 'email_captcha' && actions.length == 1) {
          const params = { 
            risk_id: info.risk_id,
            email: params.email,
            login_from: loginPageFrom(),
            cb: () => {
              this.handleLogin()
            }
          }
          setTimeout(()=> {
            this.changeDialogs({
              key: 'showEmailValidate',
              val: { show: true, riskInfo: params },
            })
          }, 0)
          return
        }
      }
      this.$toast(tips)
    },
    async handleLogin () {
      this.$evt.$emit('event-loading', true)
      const params = {
        alias: this.input.value,
        login_type: formatLoginType(this.thirdType),
        alias_type: 1,
        third_party_type: this.thirdType,
        verification_code: this.stepTwo.code,
      }
      const data = await codeVerfiyLogin(params)
      const { code, tips } = data
      daEventCenter.triggerNotice({ daId: '2-8-17', extraData: { type: this.dialogs?.thirdEmailLoginInfo?.type, success: code == 0 } })
      this.$evt.$emit('event-loading', false)
      if (code == 0) {
        this.$evt?.$emit('request-third-success', { res: data, type: this.dialogs?.thirdEmailLoginInfo?.type })
        this.handleClose()
        setTimeout(function () {
          urlRedirection(false, data)
        }, 200)
        return
      }
      this.$toast(tips)
    },
  }
}
</script>


<style lang="less">
.page__login-thirdEmailLoginPop {
// .pop-third-email-login {
  padding: 30px 24px;
  position: relative;
  .close {
    position: absolute;
    top: 10px;
    .right(10px);
    font-size: 20px;
    color: #999999;
  }
  h4 {
    .font-dpr(36px);
    text-align: center;
  }
  .desc {
    color: #666666;
    .font-dpr(28px);
    line-height: 1.2;
    padding: 20px 0;
  }
  .actions {
    padding: 40px 0 0 0;
  }
  .input-area {
    position: relative;
    .icon-sucess {
      position: absolute;
      top: 50%;
      .right(0px);
      transform: translateY(-50%);
      color: #5EBD66;
      font-size: 18px;
    }
    .code-btn {
      position: absolute;
      top: 50%;
      .right(0px);
      transform: translateY(-50%);
    }
  }
  .code-tips {
    color: #959595;
    .font-dpr(24px);
    line-height: 1.2;
    padding: 12px 0 0 0.32rem;
  }
}
.page__login-thirdEmailLoginPop_tips {
  p {
    font-weight: bold;
    .font-dpr(32px);
    line-height: 1.2;
    text-align: center;
  }
  .actions {
    padding: 20px 0;
  }
}
</style>
